<template>
  <div id="h-group" class="h-search">
    <el-input
      class="mb10 fl wid-15 mr10"
      size="small"
      clearable
      v-model="keyword"
      placeholder="群组ID/群名称模糊查询"
      @change="handleKeywordChange"
    ></el-input>
    <el-button
      class="mb10 fl wid-5"
      size="small"
      type="primary"
      @click="handleSearch"
    >
      查询
    </el-button>
  </div>
</template>

<script>
export default {
  name: "h-group",
  data() {
    return {
      data: {},
      keyword: "",
    };
  },
  props: {
    params: {
      type: Object,
      default: function () {
        return {
          keyword: "",
        };
      },
    },
  },
  created() {
    this.keyword = this.params.keyword;
  },
  methods: {
    handleKeywordChange(val) {
      if (val) {
        this.data["keyword"] = val;
      } else {
        delete this.data.keyword;
      }
    },
    handleSearch() {
      this.$emit("change", this.data);
    },
  },
};
</script>
