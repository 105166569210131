import api from "@/api";

const group = {
  list(params) {
    return api.get(`/group`, {
      params: params,
    });
  },
  listMember(id) {
    return api.get(`/group/${id}/member`);
  },
  changeStoreState(pk, data) {
    return api.patch(`/group/${pk}/store/state`, data);
  },
  changeState(pk, data) {
    return api.patch(`/group/${pk}/state`, data);
  },
};

export default group;
