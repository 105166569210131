<template>
  <div id="d-group-member">
    <el-dialog :visible.sync="visible" title="群成员列表">
      <el-table :data="data" size="mini" border>
        <el-table-column label="昵称" prop="nickname"> </el-table-column>
        <el-table-column label="手机号码" prop="cellphone"></el-table-column>
        <el-table-column label="wq_id" prop="miquan_id"></el-table-column>
        <el-table-column label="角色" prop="role">
          <template slot-scope="scope">
            {{ scope.row.role | formatGroupMemberRole }}
          </template>
        </el-table-column>
        <el-table-column label="加入时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="handleClose('form')"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import group from "@/api/group";

export default {
  name: "d-group-member",
  data() {
    return {
      visible: false,
      data: [],
    };
  },
  methods: {
    handleShow(id) {
      this.visible = true;
      group.listMember(id).then((res) => {
        this.data = res.results;
      });
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
