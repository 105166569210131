<template>
  <div id="group" v-loading="loading">
    <div class="cm-content">
      <h-group :params="params" @change="handleSearch"></h-group>
      <br />
      <el-table :data="data">
        <el-table-column label="头像" width="80">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.avatar_url"
              :src="scope.row.avatar_url"
              :preview-src-list="[scope.row.avatar_url]"
              class="avatar"
            >
            </el-image>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="群组ID" prop="group_id"></el-table-column>
        <el-table-column label="群名称" prop="name">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.name.length > 10"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.name"
            >
              <div slot="reference">{{ scope.row.name.substr(0, 10) }}...</div>
            </el-popover>
            <div v-else-if="scope.row.name.length > 0">
              {{ scope.row.name }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="群公告">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.bulletin.length > 10"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.bulletin"
            >
              <div slot="reference">
                {{ scope.row.bulletin.substr(0, 10) }}...
              </div>
            </el-popover>
            <div v-else-if="scope.row.bulletin.length > 0">
              {{ scope.row.bulletin }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="成员人数" prop="member_count">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleListMember(scope.row.group_id)"
              >{{ scope.row.member_count }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          width="280"
          label="群主ID"
          prop="owner_id"
        ></el-table-column>
        <el-table-column label="群聊状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatGroupState }}
          </template>
        </el-table-column>
        <el-table-column label="店铺状态" v-if="objInformation.is_super === -1">
          <template slot-scope="scope">
            {{ scope.row.store_state | formatStoreState }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.store_state === -1 && objInformation.is_super === -1
              "
              size="mini"
              @click="handleChangeStoreState(scope.row.pk, { store_state: 1 })"
              >开店</el-button
            >
            <el-button
              v-if="
                scope.row.store_state === 1 && objInformation.is_super === -1
              "
              size="mini"
              @click="handleChangeStoreState(scope.row.pk, { store_state: -1 })"
              >关店</el-button
            >
            <el-button
              v-if="scope.row.state === -1"
              size="mini"
              type="primary"
              @click="handleChangeState(scope.row.pk, { state: 1 })"
            >
              解封
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              size="mini"
              type="warning"
              @click="handleChangeState(scope.row.pk, { state: -1 })"
            >
              封群
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-group-member ref="dialogGroupMember"></d-group-member>
    </div>
  </div>
</template>

<script>
import HGroup from "@/components/headers/HGroup";
import DGroupMember from "@/components/dialogs/DGroupMember";

import group from "@/api/group";
import { mapState } from "vuex";

export default {
  name: "group",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState({
      objInformation: (state) => state.objInformation,
    }),
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HGroup,
    DGroupMember,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
      if (route.query.keyword) {
        this.params["keyword"] = route.query.keyword;
      }
    },
    handleList(params) {
      group.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleListMember(id) {
      this.$refs.dialogGroupMember.handleShow(id);
    },
    handleChangeStoreState(pk, data) {
      this.$confirm("是否修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          group
            .changeStoreState(pk, data)
            .then(() => {
              this.handleList(this.params);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleChangeState(pk, data) {
      this.$confirm("是否修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          group
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "group",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.$router
        .push({
          name: "group",
          query: val,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "group") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus" scope>
#group {
  display: flex;
  height: 100%;

  .avatar {
    width: 40px;
    height: 40px;
  }
}
</style>
